import React from "react";
import groom from "../assets/img/groom.jpg"
import bride from "../assets/img/bride.jpg"
const Couple = () =>{
    return(
        <div className="section couple">
            <div className="container">
                <h2 className="text-pink text-medium text-garamond pb-5">Meet the Couple</h2>
                <div className="row groom">
                    <div className="col-md-12 col-lg-6 p-0">
                        <img src={groom} alt="The Groom"/>
                    </div>
                    <div className="col-md-12 col-lg-6 p-5 bg-white pb-4">
                        <h2 className="text-pink text-medium text-garamond pt-5 pb-4">The Groom</h2>
                        <p className="pb-4">
                            Jonathan, a tech-savvy web developer with a passion for gaming and love for cats, brings both creativity and focus into everything he does. Whether he's building digital solutions or gaming, his dedication and attention to detail shine through. With a laid-back yet driven personality, he’s ready to embark on this new chapter, excited to celebrate the journey ahead with those closest to him.
                        </p>
                    </div>
                </div>
                <div className="row bride">
                    <div className="col-md-12 col-lg-6 p-5 bg-white">
                        <h2 className="text-pink text-medium text-garamond pt-5 pb-4">The Bride</h2>
                        <p  className="pb-4">
                            Iris, a vibrant and creative soul with a passion for photography and capturing life’s moments through videos, is stepping into this new chapter with grace and joy. Her love for sharing her life’s journey through visual storytelling complements her dedication to meaningful connections. She brings warmth and energy to every moment, and she’s excited to celebrate this special day surrounded by loved ones as she embarks on this beautiful journey.
                        </p>
                    </div>
                    <div className="col-md-12 col-lg-6 p-0">
                        <img src={bride} alt="The Bride"/>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Couple;