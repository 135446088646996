import React from "react";
const Header = () =>{
    return(
       <div className="section header">
            <div className="main-info">
                <h2 className="text-pink text-large text-garamond pb-5 desktop">Athan &amp; Iris</h2>
                <h2 className="text-pink text-medium text-garamond pb-5 mobile">Athan &amp; Iris</h2>
                <h1 className="text-pink cursive">Say Yes</h1>
                <h2 className="text-pink text-medium text-garamond">02.04.2025</h2>
            </div>
        </div>
    )
}
export default Header;