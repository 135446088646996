import React from "react";
import { NavLink } from "react-router-dom";
const FAQ = () =>{
    return(
        <div className="section faq">
            <div className="container py-5">
                <h2 className="text-pink text-medium text-garamond pb-5 mb-5">Frequently Asked Questions</h2>
                <div className="row pb-4">
                    <div className="col-xs-12 col-md-6 text-left">
                        <h4 className="pb-4" >When and where is the wedding?</h4>
                    </div>
                    <div className="col-xs-12 col-md-6 text-left">
                        <p className="">
                            Tuesday, February 4, 2025.<br/>
                            FarmPlate, Gabawan, Daraga, Albay <br/><br/>
                            <NavLink className="text-pink" to="/event">Full Details&gt;&gt;</NavLink>
                        </p>
                    </div>
                </div>
                <hr/>
                <div className="row pb-4">
                    <div className="col-xs-12 col-md-6 text-left">
                        <h4 className="pb-4" >Is there a theme for the wedding?</h4>
                    </div>
                    <div className="col-xs-12 col-md-6 text-left">
                        <p className="">
                            Yes, the theme for the wedding is spring! <br/><br/>
                            We're excited to celebrate with vibrant colors and floral elements that capture the beauty of the season.<br/><br/>
                            <NavLink className="text-pink" to="/theme">Full Details&gt;&gt;</NavLink>
                        </p>
                    </div>
                </div>
                <hr/>
                <div className="row pb-4">
                    <div className="col-xs-12 col-md-6 text-left">
                        <h4 className="pb-4" >What is the dress code?</h4>
                    </div>
                    <div className="col-xs-12 col-md-6 text-left">
                        <p className="">
                            Please wear semi-formal attire—no jeans, t-shirts, or slippers. <br/><br/>
                            We kindly ask women to avoid white or similar shades<br/><br/>
                            <NavLink className="text-pink" to="/theme">Full Details&gt;&gt;</NavLink>
                        </p>
                    </div>
                </div>
                <hr/>
                <div className="row pb-4">
                    <div className="col-xs-12 col-md-6 text-left">
                        <h4 className="pb-4" >Is the wedding indoors or outdoors?</h4>
                    </div>
                    <div className="col-xs-12 col-md-6 text-left">
                        <p className="">
                            Both the wedding ceremony and reception will be held outdoors. <br/><br/>
                            We hope you will enjoy the beautiful surroundings as we celebrate this special day together. <br/><br/>
                            Please dress comfortably and consider bringing items for sun or rain protection as needed. 
                        </p>
                    </div>
                </div>
                <hr/>
                <div className="row pb-4">
                    <div className="col-xs-12 col-md-6 text-left">
                        <h4 className="pb-4" >Can I bring a plus-one?</h4>
                    </div>
                    <div className="col-xs-12 col-md-6 text-left">
                        <p className="">
                            Due to limited space, we can only accommodate invited guests. <br/><br/>
                            If you have a plus one, an input field will appear to fill in your guest's name when you RSVP <span className="text-bold text-underline">'Yes'</span>.
                       </p>
                    </div>
                </div>
                <hr/>
                <div className="row pb-4">
                    <div className="col-xs-12 col-md-6 text-left">
                        <h4 className="pb-4" >Can we bring our children?</h4>
                    </div>
                    <div className="col-xs-12 col-md-6 text-left">
                        <p className="">
                            Invitations will specify if your children are invited, but we encourage you to enjoy a "date night" instead! <br/><br/>
                            If your children are invited, please supervise them during the celebration. If they become restless, we kindly request you to take them outside to avoid disruptions. <br/><br/>
                            If your kid(s) is/are invited, input field(s) will appear to fill in your children's name when you RSVP <span className="text-bold text-underline">'Yes'</span>.
                       </p>
                    </div>
                </div>
                <hr/>
                <div className="row pb-4">
                    <div className="col-xs-12 col-md-6 text-left">
                        <h4 className="pb-4" >How do I RSVP?</h4>
                    </div>
                    <div className="col-xs-12 col-md-6 text-left">
                        <p className="">
                            Please click the RSVP button in the menu or at the bottom of the page. <br/><br/>
                            Kindly complete the form by <span className="text-bold text-underline">December 15, 2024</span>. If you can't attend, we'd appreciate hearing from you as soon as possible.
                        </p>
                    </div>
                </div>
                <hr/>
                <div className="row pb-4">
                    <div className="col-xs-12 col-md-6 text-left">
                        <h4 className="pb-4" >What should I do if I need to change my RSVP?</h4>
                    </div>
                    <div className="col-xs-12 col-md-6 text-left">
                        <p className="">
                            You can RSVP multiple times using the form to update guest's names, details, or your attendance. <br/><br/>
                            Please note that we will only honor the <span className="text-bold text-underline">most recent</span> entry received by the deadline.
                        </p>
                    </div>
                </div>
                <hr/>
                <div className="row pb-4">
                    <div className="col-xs-12 col-md-6 text-left">
                        <h4 className="pb-4" >What time does the ceremony start?</h4>
                    </div>
                    <div className="col-xs-12 col-md-6 text-left">
                        <p className="">
                            The ceremony is scheduled to start at <span className="text-bold text-underline">4:00 PM</span>.
                        </p>
                    </div>
                </div>
                <hr/>
                <div className="row pb-4">
                    <div className="col-xs-12 col-md-6 text-left">
                        <h4 className="pb-4" >What time does the reception end?</h4>
                    </div>
                    <div className="col-xs-12 col-md-6 text-left">
                        <p className="">
                            We are so excited to celebrate our special day with you! We’ve planned the evening with love and care, and your presence means the world to us. <br/><br/>
                            The reception is scheduled to end at <span className="text-bold text-underline">9:00 PM</span>, but we kindly ask that you stay and enjoy the entire reception, as we have some special moments planned throughout the night that we would love for you to be a part of.
                        </p>
                    </div>
                </div>
                <hr/>
                <div className="row pb-4">
                    <div className="col-xs-12 col-md-6 text-left">
                        <h4 className="pb-4" >Will the ceremony and reception be at the same location?</h4>
                    </div>
                    <div className="col-xs-12 col-md-6 text-left">
                        <p className="">
                            We are delighted to inform you that the wedding ceremony and reception will be held at the same venue. <br/><br/>
                            This will make it convenient for everyone to celebrate together without the need for travel.
                        </p>
                    </div>
                </div>
                <hr/>
                <div className="row pb-4">
                    <div className="col-xs-12 col-md-6 text-left">
                        <h4 className="pb-4" >Is there parking available at the venue?</h4>
                    </div>
                    <div className="col-xs-12 col-md-6 text-left">
                        <p className="">
                            Yes, there is parking available at the venue for your convenience.
                        </p>
                    </div>
                </div>
                <hr/>
                <div className="row pb-4">
                    <div className="col-xs-12 col-md-6 text-left">
                        <h4 className="pb-4" >Do you have a hotel block for guests?</h4>
                    </div>
                    <div className="col-xs-12 col-md-6 text-left">
                        <p className="">
                            We do not have a hotel block for guests, but you are welcome to book accommodations at your own expense.<br/><br/>
                            You also have the option to book accommodations at <a className="text-pink text-bold text-underline" href="https://www.facebook.com/FarmPlateAlbay" target="_blank" rel="noreferrer">Farmplate</a>, which offers charming barn cabins. Please feel free to contact them directly to inquire about availability and rates. <br/><br/>
                            If you need other recommendations, <a href="https://www.google.com/travel/search?q=hotel%20near%20farm%20plate%20daraga%20albay&g2lb=4814050%2C4874190%2C4893075%2C4965990%2C4969803%2C72277293%2C72302247%2C72317059%2C72406588%2C72414906%2C72421566%2C72471280%2C72472051%2C72473841%2C72481459%2C72485658%2C72536387%2C72602734%2C72614662%2C72616120%2C72619927%2C72620306%2C72647020%2C72648289%2C72658035%2C72671093%2C72686036%2C72729615%2C72749231%2C72757848%2C72766789%2C72770823&hl=en-PH&gl=ph&cs=1&ssta=1&ts=CAESCAoCCAMKAggDGhwSGhIUCgcI6A8QChgTEgcI6A8QChgUGAEyAhAAKgcKBToDUEhQ&qs=CAE4BkIJEXm2AeKCb8_DQgkRr-XOjwhQTkdCCRG1o1-nun1K7lpbMlmqAVYQASoJIgVob3RlbCgAMh8QASIbBMEqjpgwc1cjsnpaLDXiFA2U_IVYpjAqAO2OMiYQAiIiaG90ZWwgbmVhciBmYXJtIHBsYXRlIGRhcmFnYSBhbGJheQ&ap=aAE&ictx=111&ved=0CAAQ5JsGahcKEwiYi7PUyfiIAxUAAAAAHQAAAAAQDA" target="_blank" rel="noreferrer" className="text-pink text-bold text-underline ">click here</a>.
                        </p>
                    </div>
                </div>
                <hr/>
                <div className="row pb-4">
                    <div className="col-xs-12 col-md-6 text-left">
                        <h4 className="pb-4" >What type of food will be served?</h4>
                    </div>
                    <div className="col-xs-12 col-md-6 text-left">
                        <p className="">
                            The food served will be based on your responses to the food allergy and restriction inquiry. <br/><br/>
                            We aim to accommodate everyone's preferences and ensure a delightful dining experience!
                        </p>
                    </div>
                </div>
                <hr/>
                <div className="row pb-4">
                    <div className="col-xs-12 col-md-6 text-left">
                        <h4 className="pb-4" >What if I have dietary restrictions?</h4>
                    </div>
                    <div className="col-xs-12 col-md-6 text-left">
                        <p className="">
                            To ensure everyone has an enjoyable dining experience, we will be inquiring about any food restrictions or allergies you may have. <br/><br/>
                            An input field will appear to fill in your food allergies or restrictions when you RSVP <span className="text-bold text-underline">'Yes'</span>.
                        </p>
                    </div>
                </div>
                <hr/>
                <div className="row pb-4">
                    <div className="col-xs-12 col-md-6 text-left">
                        <h4 className="pb-4" >Can I take photos during the ceremony?</h4>
                    </div>
                    <div className="col-xs-12 col-md-6 text-left">
                        <p className="">
                            We kindly request an "unplugged" ceremony, so please silence or turn off your phones. <br/><br/>
                            A professional photographer will be capturing the day, and we appreciate your cooperation in keeping the moment phone-free.  <br/><br/>
                            After the ceremony, you are welcome to take as many photos as you like!
                        </p>
                    </div>
                </div>
                <hr/>
                <div className="row pb-4">
                    <div className="col-xs-12 col-md-6 text-left">
                        <h4 className="pb-4" >Do you have a gift list or registry set up?</h4>
                    </div>
                    <div className="col-xs-12 col-md-6 text-left">
                        <p className="">
                            Your presence is what matters most to us! <br/><br/>
                            Gifts aren't necessary, but if you'd like to contribute, we'd appreciate any monetary support towards our honeymoon.
                        </p>
                    </div>
                </div>
                <hr/>
                <div className="row pb-5">
                    <div className="col-xs-12 col-md-6 text-left">
                        <h4 className="pb-4" >What's the best way to contact you for questions?</h4>
                    </div>
                    <div className="col-xs-12 col-md-6 text-left pb-5">
                        <p className="pb-5">
                            You can give us a call or send a text message at these numbers: <br/><br/>
                            <span className="text-pink text-small text-garamond">The Groom</span><br/>
                            <span>(+63)9171502876</span><br/><br/>
                            <span className="text-pink text-small text-garamond">The Bride</span><br/>
                            <span>(+63)9165364691</span><br/><br/>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FAQ;