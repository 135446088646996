import React from "react";
const Message = () =>{
    return(
        <div className="section message">
            <div className="container">
                <p className="mb-5 cursive text-large text-pink pb-5 mb-5 extra-message">All because two people swiped right...</p>
                <p className="text-small text-garamond mb-5">
                    We cordially invite you to share in the joy as we  exchange <br className="desktop"/>vows and celebrate our marriage alongside family and friends.
                </p>
            </div>
        </div>
    )
}
export default Message;