import React from "react";
import Header from './Header';
import Message from './Message';
import Event from './Event';
import Couple from './Couple';
import Theme from './Theme';
import RSVP from './RSVP';
const Main = () =>{
    return(
       <div>
            <Header/>
            <Message/>
            <Event/>
            <Couple/>
            <Theme/>
            <RSVP/>
       </div>
    )
}
export default Main;