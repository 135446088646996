import React from "react";
const EventDetails = () =>{
    return(
        <div className="details">
            <div className="container pb-5 mb-5">
                <div className="row pb-5">
                    <div className="col-xs-12 col-md-6">
                        <h2 className="text-pink text-medium text-garamond pt-2 pb-4">When</h2>
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <p className="text-pink text-medium text-garamond pt-2">February 4, 2025</p>
                        <p className="text-pink text-small  pb-2">Tuesday</p>
                        <p className="pb-5">
                            We are getting married on February 4th, a date that holds special significance for us as it marks not only our wedding day but also our 104th month together. It’s a beautiful milestone in our journey, and we’re thrilled to celebrate it with all of you!
                        </p>
                        <p className="text-pink text-small">Ceremony: 4 PM - 5 PM</p>
                        <p className="pb-5">Our ceremony will be held outdoors so please prepare mini-fans and umbrellas.</p>
                        <p className="text-pink text-small">Snacks and Photos: 5 PM - 6 PM</p>
                        <p className="pb-5">
                            Enjoy delicious snacks and drinks while taking in the stunning scenery in FarmPlate. <br/>
                            Feel free to take photos! We'd love to see them!
                        </p>
                        <p className="text-pink text-small">Reception: 6 PM - 9 PM</p>
                        <p className="pb-5">Our caterers will be serving delicious food while we listen to heartwarming speeches.</p>
                        <p className="text-pink text-small">Programme coming soon!</p>
                    </div>
                </div>
                <hr/>
                <div className="row pb-5 mb-5">
                    <div className="col-xs-12 col-md-6">
                        <h2 className="text-pink text-medium text-garamond pt-2 pb-4">Where</h2>
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <p className="text-pink text-medium text-garamond pt-2">FarmPlate</p>
                        <p className="text-pink text-small pb-2">Gabawan, Daraga, Albay</p>
                        <p>
                            We absolutely love the spring season, and what better way to celebrate it than outdoors at the smallest chapel in the Philippines, with the stunning Mayon Volcano as our backdrop!
                        </p>
                        <p>
                            <a className="text-pink" href="https://maps.app.goo.gl/qEDFYb4GgxoRU4Zu9" target="_blank"  rel="noreferrer" title="FarmPlate Map">View Map</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default EventDetails;