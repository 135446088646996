import './App.css';
import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import ScrollToTop from "./components/ScrollToTop";
import Navbar from './components/Navbar';
import Main from './components/Main';
import ThemeDetails from './components/ThemeDetails';
import EventDetails from './components/EventDetails';
import RSVPForm from './components/RSVPForm';
import ThankYou from './components/ThankYou';
import FAQ from './components/FAQ';
import FooterNavbar from './components/FooterNavbar';
import Footer from './components/Footer';
import emailjs from '@emailjs/browser';

function App() {
  useEffect(() => emailjs.init("EIS-v23mhI1H_QQfe"), []);
  return (
    <>
      <ScrollToTop />
      <Navbar/>
      <Routes>
        <Route path="/" element={<Main/>} />
        <Route path="/theme" element={<ThemeDetails />} />
        <Route path="/event" element={<EventDetails />} />
        <Route path="/rsvp" element={<RSVPForm />} />
        <Route path="/thankyou" element={<ThankYou />} />
        <Route path="/faq" element={<FAQ />} />
      </Routes>
      <FooterNavbar/>
      <Footer/>
    </>
  );
}

export default App;
