import React from "react";
import { NavLink } from "react-router-dom";
const FooterNavbar = () =>{
    return(
        <>
            <div className="no-print">
                <div className="footer-navigation">
                    <div className="navigation-items">
                        <NavLink to="/">Home</NavLink>
                        <NavLink to="/event">Event</NavLink>
                        <NavLink to="/theme">Theme</NavLink>
                        <NavLink to="/faq">FAQ</NavLink>
                        <NavLink to="/rsvp">RSVP</NavLink>
                    </div>
                </div>
            </div>
        </>
    )
}
export default FooterNavbar;