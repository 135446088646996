import React from "react";
import { NavLink } from "react-router-dom";
const RSVP = () =>{
    return(
        <div className="section rsvp">
            <div className="container">
                <h2 className="text-pink text-garamond pb-5 rsvp-message">We hope you'll join us <br/>on this special day.</h2>
                <NavLink to="/rsvp" className="rsvp-button">RSVP</NavLink>
            </div>
        </div>
    )
}
export default RSVP;