import React from "react";
import { NavLink } from "react-router-dom";
const Theme = () =>{
    return(
        <div className="section theme">
            <div className="container">
                <h2 className="text-pink text-medium text-garamond pb-5">Theme</h2>
                <p className="text-small">We love to see our family and friends get fancy with us!</p>
                <p className="text-small">Get inspired by the beautiful colors of <strong className="text-garamond">SPRING</strong>.</p>
                <p className="text-small pb-5">We can't wait to capture everlasting moments with you in your fabulous outfits.</p>
                <NavLink className="text-pink" to="/theme">Full Details&gt;&gt;</NavLink>
            </div>
        </div>
    )
}
export default Theme;