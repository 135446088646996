import React from "react";
import men1 from "../assets/img/men1.jpeg";
import men2 from "../assets/img/men2.jpeg";
import men3 from "../assets/img/men3.jpeg";
import men4 from "../assets/img/men4.jpeg";
import men5 from "../assets/img/men5.jpeg";
import men6 from "../assets/img/men6.jpeg";
import wmen1 from "../assets/img/wmen1.jpeg";
import wmen2 from "../assets/img/wmen2.jpeg";
import wmen3 from "../assets/img/wmen3.jpeg";
import wmen4 from "../assets/img/wmen4.jpeg";
import wmen5 from "../assets/img/wmen5.jpeg";
import wmen6 from "../assets/img/wmen6.jpeg";
const ThemeDetails = () =>{
    return(
        <div className="motif text-center">
            <div className="container pb-5 mb-5">
                <h3 className="text-pink text-medium text-garamond py-3">Ladies</h3>
                <p className="text-small pb-5">
                    You may wear midi or maxi dresses.<br/>
                    Reminder to wear comfortable shoes and avoid high heels since our location is steep and might be difficult to walk in.
                </p>
                <div className="row pb-5">
                    <div className="col-md-2 pb-2"><img src={wmen1} alt=""/></div>
                    <div className="col-md-2 pb-2"><img src={wmen2} alt=""/></div>
                    <div className="col-md-2 pb-2"><img src={wmen3} alt=""/></div>
                    <div className="col-md-2 pb-2"><img src={wmen4} alt=""/></div>
                    <div className="col-md-2 pb-2"><img src={wmen5} alt=""/></div>
                    <div className="col-md-2 pb-2"><img src={wmen6} alt=""/></div>
                </div>
                <hr/>
                <h3 className="text-pink text-medium text-garamond py-3">Gentlemen</h3>
                <p className="text-small pb-5">
                    You may wear black or gray slacks, long or short-sleeves in spring colors.
                </p>
                <div className="row pb-5">
                    <div className="col-md-2 pb-2"><img src={men1} alt=""/></div>
                    <div className="col-md-2 pb-2"><img src={men2} alt=""/></div>
                    <div className="col-md-2 pb-2"><img src={men3} alt=""/></div>
                    <div className="col-md-2 pb-2"><img src={men4} alt=""/></div>
                    <div className="col-md-2 pb-2"><img src={men5} alt=""/></div>
                    <div className="col-md-2 pb-2"><img src={men6} alt=""/></div>
                </div>
                <hr/>
                <h3 className="text-pink text-medium text-garamond py-5">Color Palette</h3>
                <p className="mb-5 pb-5">
                    <span className="color1"></span>
                    <span className="color2"></span>
                    <span className="color3"></span>
                    <span className="color4"></span>
                </p>
            </div>
        </div>
    )
}
export default ThemeDetails;