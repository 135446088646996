import React from "react";
import { useState, useEffect } from "react";
const ThankYou = () =>{
    const [guestDetails, setGuestDetails] = useState();
    useEffect(() => {
        setGuestDetails(JSON.parse(sessionStorage.getItem('GuestDetails')));
    }, []);
    return(
       <div className="section invitation">
            <div className="main-info">
                <h2 className="text-pink text-xlarge cursive pb-5">Thank You</h2>
                {guestDetails &&
                guestDetails.rsvp === 'Yes' &&
                    <>
                        <p className="text-small text-center text-garamond pb-5">
                            We are thrilled that you'll be joining us on our special day and can't wait to celebrate with you. <br/>
                            Your presence will mean the world to us, and we look forward to creating beautiful memories together.
                        </p>
                        <p className="text-small text-center text-garamond pb-5">
                            Stay tuned for more details as we get closer to the big day!
                        </p>
                    </>
                }
                {guestDetails &&
                guestDetails.rsvp === 'No' &&
                    <>
                        <p className="text-small text-center text-garamond pb-5">
                            Thank you so much for letting us know you won’t be able to attend our wedding. <br/>
                            While we’ll miss having you there, we completely understand, and we appreciate you thinking of us during this special time.
                        </p>
                        <p className="text-small text-center text-garamond pb-5">
                            We hope to catch up with you soon and share some memories from the big day!
                        </p>
                    </>
                }
            </div>
        </div>
    )
}
export default ThankYou;