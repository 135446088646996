import React from "react";
import { NavLink } from "react-router-dom";
const Event = () =>{
    return(
        <div className="section event">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-md-6 pb-5 mb-5">
                        <h2 className="text-small">When</h2>
                        <p className="text-pink text-medium text-garamond pt-3">February 4, 2025</p>
                        <p className="text-pink text-small">Ceremony: 4 PM - 5 PM <br/> Reception: 5 PM - 9 PM</p>
                        <NavLink className="text-pink" to="/event">Full Details&gt;&gt;</NavLink>
                    </div>
                    <div className="col-xs-12 col-md-6 pb-5 mb-5">
                        <h2 className="text-small">Where</h2>
                        <p className="text-pink text-medium text-garamond pt-3">FarmPlate</p>
                        <p className="text-pink text-small">Gabawan, Daraga, Albay <br/> Philippines</p>
                        <NavLink className="text-pink" to="/event">Full Details&gt;&gt;</NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Event;