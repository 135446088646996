import React from "react";
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
const Navbar = () =>{
    const [navbarScrolled, setNavbarScrolled] = useState(false);

    useEffect(() => {
      const handleScroll = () => {
        const scrollPosition = window.scrollY; // Get the current scroll position
        // Check if the scroll position is greater than a small threshold (e.g., 50px)
        if (scrollPosition > 200) {
          setNavbarScrolled(true);
        } else {
          setNavbarScrolled(false);
        }
      };

      window.addEventListener('scroll', handleScroll);

      // Cleanup listener on component unmount
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
    return(
        <>
            <div className="desktop">
                <div className={navbarScrolled ? 'navigation no-print scrolled' : 'navigation no-print'}>
                    <NavLink to="/faq" style={{color:'#8b8b8b'}}>FAQ</NavLink>
                    {navbarScrolled && (
                      <NavLink to="/" className="text-pink text-medium text-garamond" style={{width:'350px'}}>Athan &amp; Iris</NavLink>
                    )}
                    <NavLink to="/rsvp" className="text-small rsvp-button">RSVP</NavLink>
                </div>
            </div>
            <div className="mobile">
                <div className="navigation no-print">
                    <NavLink to="/" className="text-pink text-medium text-garamond">Athan &amp; Iris</NavLink>
                    <NavLink to="/rsvp" className="text-small rsvp-button">RSVP</NavLink>
                </div>
            </div>
        </>
    )
}
export default Navbar;