import React, { useState, useEffect, useRef } from "react";
import emailjs from '@emailjs/browser';
import { useNavigate } from "react-router-dom";
const RSVPForm = () =>{
    const navigate = useNavigate();
    const form = useRef();
    const [isDisabled, setIsDisabled] = useState(true);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [rsvp, setRsvp] = useState('');
    const [guestFirstName, setGuestFirstName] = useState('');
    const [guestLastName, setGuestLastName] = useState('');
    const [kidFirstName, setKidFirstName] = useState('');
    const [kidLastName, setKidLastName] = useState('');
    const [kidTwoFirstName, setKidTwoFirstName] = useState('');
    const [kidTwoLastName, setKidTwoLastName] = useState('');
    const [allergies, setAllergies] = useState('');
    const [isGuest, setIsGuest] = useState(null);
    const [isGuestWithPlusOneList, setIsGuestWithPlusOneList] = useState(null);
    const [isGuestWithOneKidList, setGuestWithOneKidList] = useState(null);
    const [isGuestWithTwoKidsList, setGuestWithTwoKidsList] = useState(null);
    const [isDisplayRSVP, setIsDisplayRSVP] = useState(null);
    const [loading, setLoading] = useState(false);
    const [checkSubmitted, setCheckSubmitted] = useState(false);
    const rsvpCheckList = [
        {firstName: 'Daisy', lastName: 'Loquinario'},
        {firstName: 'Daryl', lastName: 'Loquinario'},
        {firstName: 'Laisa', lastName: 'Desolo'},
        {firstName: 'Laisa Maoren', lastName: 'Desolo'},
        {firstName: 'Laisa', lastName: 'Tatel'},
        {firstName: 'Laisa Maoren', lastName: 'Tatel'},
        {firstName: 'Dioma', lastName: 'Loquinario'},
        {firstName: 'Uma', lastName: 'Loquinario'},
        {firstName: 'Gil', lastName: 'Zulueta'},
        {firstName: 'Gil Mark', lastName: 'Zulueta'},
        {firstName: 'Markki', lastName: 'Zulueta'},
        {firstName: 'Eric', lastName: 'Baduria'},
        {firstName: 'Eric Val', lastName: 'Baduria'},
        {firstName: 'Ryan', lastName: 'Avila'},
        {firstName: 'Ryan Jay', lastName: 'Avila'},
        {firstName: 'Deborah', lastName: 'Buendia'},
        {firstName: 'Deborah', lastName: 'Nolasco'},
        {firstName: 'Debbie', lastName: 'Buendia'},
        {firstName: 'Debbie', lastName: 'Nolasco'},
        {firstName: 'Maria Peñafrancia', lastName: 'Deveza'},
        {firstName: 'Maria Penafrancia', lastName: 'Deveza'},
        {firstName: 'Penny', lastName: 'Deveza'},
        {firstName: 'Diana', lastName: 'Renolayan'},
        {firstName: 'Diana Fe', lastName: 'Renolayan'},
        {firstName: 'Pepot', lastName: 'Renolayan'},
        {firstName: 'Diana', lastName: 'Somera'},
        {firstName: 'Diana Fe', lastName: 'Somera'},
        {firstName: 'Pepot', lastName: 'Somera'},
        {firstName: 'Giselle', lastName: 'Rocha'},
        {firstName: 'Ghie', lastName: 'Rocha'},
        {firstName: 'Giselle', lastName: 'Dayag'},
        {firstName: 'Ghie', lastName: 'Dayag'},
        {firstName: 'Jean', lastName: 'Regaspi'},
        {firstName: 'Jean Maricon', lastName: 'Regaspi'},
        {firstName: 'Maricon', lastName: 'Regaspi'},
        {firstName: 'John', lastName: 'Pahati'},
        {firstName: 'John Homer', lastName: 'Pahati'},
        {firstName: 'Homer', lastName: 'Pahati'},
        {firstName: 'Mizpah', lastName: 'Bañares'},
        {firstName: 'Mizpah Jairus', lastName: 'Bañares'},
        {firstName: 'Jairus', lastName: 'Bañares'},
        {firstName: 'Mizzy', lastName: 'Bañares'},
        {firstName: 'Mizpah', lastName: 'Banares'},
        {firstName: 'Mizpah Jairus', lastName: 'Banares'},
        {firstName: 'Jairus', lastName: 'Banares'},
        {firstName: 'Mizzy', lastName: 'Banares'},
        {firstName: 'Evaristo', lastName: 'Pandi'},
        {firstName: 'Evaristo Godofredo', lastName: 'Pandi'},
        {firstName: 'Ivan', lastName: 'Pandi'},
        {firstName: 'Victoria', lastName: 'Pandi'},
        {firstName: 'Vicky', lastName: 'Pandi'},
        {firstName: 'Vikki', lastName: 'Pandi'},
        {firstName: 'Vikki Joyce', lastName: 'Pandi'},
        {firstName: 'Joyce', lastName: 'Pandi'},
        {firstName: 'Vikki', lastName: 'dela Cruz'},
        {firstName: 'Vikki Joyce', lastName: 'dela Cruz'},
        {firstName: 'Joyce', lastName: 'dela Cruz'},
        {firstName: 'Ysmael', lastName: 'dela Cruz'},
        {firstName: 'Yael', lastName: 'dela Cruz'},
        {firstName: 'Marion Nikki Claire', lastName: 'Velasco'},
        {firstName: 'Nikki Claire', lastName: 'Velasco'},
        {firstName: 'Nikki', lastName: 'Velasco'},
        {firstName: 'Joseph', lastName: 'Velasco'},
        {firstName: 'Beatrice', lastName: 'Pandi'},
        {firstName: 'Beatrice Eloisa', lastName: 'Pandi'},
        {firstName: 'Bea', lastName: 'Pandi'},
        {firstName: 'Bea Eloisa', lastName: 'Pandi'},
        {firstName: 'Eloisa', lastName: 'Pandi'},
        {firstName: 'Thom', lastName: 'Orolfo'}
    ];
    const guestWithPlusOneList = [
        {firstName: 'Daryl', lastName: 'Loquinario'},
        {firstName: 'Laisa', lastName: 'Desolo'},
        {firstName: 'Laisa Maoren', lastName: 'Desolo'},
        {firstName: 'Laisa', lastName: 'Tatel'},
        {firstName: 'Laisa Maoren', lastName: 'Tatel'},
        {firstName: 'Gil', lastName: 'Zulueta'},
        {firstName: 'Gil Mark', lastName: 'Zulueta'},
        {firstName: 'Markki', lastName: 'Zulueta'},
        {firstName: 'Diana', lastName: 'Renolayan'},
        {firstName: 'Diana Fe', lastName: 'Renolayan'},
        {firstName: 'Pepot', lastName: 'Renolayan'},
        {firstName: 'Diana', lastName: 'Somera'},
        {firstName: 'Diana Fe', lastName: 'Somera'},
        {firstName: 'Pepot', lastName: 'Somera'},
        {firstName: 'Giselle', lastName: 'Rocha'},
        {firstName: 'Ghie', lastName: 'Rocha'},
        {firstName: 'Giselle', lastName: 'Dayag'},
        {firstName: 'Ghie', lastName: 'Dayag'},
    ];
    const guestWithOneKidList = [
        {firstName: 'Vikki', lastName: 'Pandi'},
        {firstName: 'Vikki Joyce', lastName: 'Pandi'},
        {firstName: 'Joyce', lastName: 'Pandi'},
        {firstName: 'Vikki', lastName: 'dela Cruz'},
        {firstName: 'Vikki Joyce', lastName: 'dela Cruz'},
        {firstName: 'Joyce', lastName: 'dela Cruz'},
        {firstName: 'Ysmael', lastName: 'dela Cruz'},
        {firstName: 'Yael', lastName: 'dela Cruz'},
    ];
    const guestWithTwoKidsList = [
        {firstName: 'Marion Nikki Claire', lastName: 'Velasco'},
        {firstName: 'Nikki Claire', lastName: 'Velasco'},
        {firstName: 'Nikki', lastName: 'Velasco'},
        {firstName: 'Joseph', lastName: 'Velasco'}
    ];
    const resetValues = () => {
        setIsGuestWithPlusOneList(false);
        setGuestFirstName('');
        setGuestLastName('');
        setAllergies('');
        setRsvp('');
    }
    const rsvpEndDateChecker = () => {
        const currentDate = new Date();
        const startDate = new Date("2024-10-01");
        const endDate = new Date("2024-12-15");
        if (currentDate >= startDate && currentDate <= endDate) {
            setIsDisplayRSVP(true);
        } else {
            setIsDisplayRSVP(false);
        }
    }
    const submissionChecker = () => {
        const checkSessionSubmission = JSON.parse(sessionStorage.getItem('GuestDetails'));
        console.log(checkSessionSubmission)
        if(checkSessionSubmission) {
            setCheckSubmitted(true);
        }
        else{
            setCheckSubmitted(false);
        }
    }
    const handleFirstName = (e) => {
        setFirstName(e.target.value);
        resetValues();
    }
    const handleLastName = (e) => {
        setLastName(e.target.value);
        resetValues();
    }
    const handleRSVP = (e) => {
        const rsvp = e.target.value;

        const nameExists = rsvpCheckList.some(
            (name) =>
              name.firstName.toLowerCase() === firstName.toLowerCase() &&
              name.lastName.toLowerCase() === lastName.toLowerCase()
        );

        const guestWithPlusOne = guestWithPlusOneList.some(
            (name) =>
              name.firstName.toLowerCase() === firstName.toLowerCase() &&
              name.lastName.toLowerCase() === lastName.toLowerCase()
        );

        const guestWithOneKid = guestWithOneKidList.some(
            (name) =>
              name.firstName.toLowerCase() === firstName.toLowerCase() &&
              name.lastName.toLowerCase() === lastName.toLowerCase()
        );

        const guestWithTwoKid = guestWithTwoKidsList.some(
            (name) =>
              name.firstName.toLowerCase() === firstName.toLowerCase() &&
              name.lastName.toLowerCase() === lastName.toLowerCase()
        );

        if(rsvp==='Yes'){
            if(nameExists){
                setIsGuest(true);
                if(guestWithPlusOne){
                    setIsGuestWithPlusOneList(true);
                }
                else{
                    setIsGuestWithPlusOneList(false);
                }
                if(guestWithOneKid){
                    setGuestWithOneKidList(true);
                }
                else{
                    setGuestWithOneKidList(false);
                }
                if(guestWithTwoKid){
                    setGuestWithTwoKidsList(true);
                }
                else{
                    setGuestWithTwoKidsList(false);
                }
                setIsDisabled(false);
            }
            else{
                setIsGuest(false);
                setIsDisabled(true);
            }
        }
        else if(rsvp==='No'){
            if(nameExists){
                setIsGuest(true);
                setIsDisabled(false);
            }
            else{
                setIsGuest(false);
                setIsDisabled(true);
            }
            resetValues();
        }
        setRsvp(rsvp);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const guestDetails ={
            firstName: firstName,
            lastName: lastName,
            email: email,
            rsvp: rsvp,
            guestFirstName: guestFirstName,
            guestLastName: guestLastName,
            kidFirstName: kidFirstName,
            kidLastName: kidLastName,
            kidTwoFirstName: kidTwoFirstName,
            kidTwoLastName: kidTwoLastName,
            allergies: allergies
        };
        sessionStorage.setItem('GuestDetails',JSON.stringify(guestDetails));
        setLoading(true);
        setIsDisabled(true);
        emailjs
        .sendForm('service_ui1vdl7', 'template_prp59af', form.current,  { publicKey: 'EIS-v23mhI1H_QQfe' })
        .then(
            () => {
                setLoading(false);
                setIsDisabled(false);
                navigate('/thankyou');
            },
            (error) => {
                setLoading(false);
                setIsDisabled(false);
                console.log('FAILED...', error.text);
            },
        );
    }
    useEffect(() => {
        rsvpEndDateChecker();
        submissionChecker();
    }, []);
    return(
        <div className="rsvp-form">
            <div className="container pb-5 mb-5">
                { checkSubmitted === true ? (
                    <>
                        <div className="text-center text-garamond text-pink py-5 my-5">
                            <h2 className="pb-5"> You already submitted your RSVP.</h2>
                            <p> If you want to make some changes, please open a new browser or tab and proceed to RSVP page. </p>
                            <p> Thank you so much. </p>
                        </div>
                    </>
                ) : (
                    <>
                        {   isDisplayRSVP === true ? (
                            <>
                                <h2 className="text-medium text-pink text-garamond text-center pb-5">RSVP</h2>
                                <p className="text-medium text-pink text-garamond text-center pb-5">Kindly RSVP by December 15, 2024</p>
                                <hr/>
                                <form className="pb-5 mb-5" onSubmit={handleSubmit} ref={form}>
                                    <div className="row form-group pt-5">
                                        <div className="col-xs-12 col-md-6 pb-4">
                                            <label for="firstName">First Name (required)</label>
                                            <input type="text" className="form-control" name="firstName" id="firstName" onChange={handleFirstName} value={firstName} required />
                                        </div>
                                        <div className="col-xs-12 col-md-6 pb-4">
                                            <label for="lastName">Last Name (required)</label>
                                            <input type="text" className="form-control" name="lastName" id="lastName" onChange={handleLastName} value={lastName} required />
                                        </div>
                                        <div className="col-12 pb-4">
                                            <label for="email">Email (required)</label>
                                            <input type="email" className="form-control" name="email" id="email" onChange={e => setEmail(e.target.value)} value={email} required />
                                        </div>
                                        <div className="col-12 pb-4">
                                            <label for="rsvp">Will you be attending? (required)</label>
                                            <select name="rsvp" id="rsvp" onChange={handleRSVP} value={rsvp} required>
                                                <option defaultValue={''} disabled></option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                        </div>
                                        {   firstName !== '' &&
                                            lastName !== '' &&
                                            email !== '' &&
                                            isGuest === true &&
                                            rsvp === 'Yes' &&
                                            <>
                                                { isGuestWithOneKidList === true &&
                                                    <>
                                                        <div className="col-12">
                                                            <label>You can bring your <strong>(1)</strong> child with you.</label><br/>
                                                            <label><strong>Kid's Name</strong></label>
                                                        </div>
                                                        <div className="col-xs-12 col-md-6 pb-4">
                                                            <label for="kidFirstName">First Name (required)</label>
                                                            <input type="text" className="form-control" name="kidFirstName" id="kidFirstName" onChange={e => setKidFirstName(e.target.value)} value={kidFirstName} required />
                                                        </div>
                                                        <div className="col-xs-12 col-md-6 pb-4">
                                                            <label for="kidLastName">Last Name (required)</label>
                                                            <input type="text" className="form-control" name="kidLastName" id="kidLastName" onChange={e => setKidLastName(e.target.value)} value={kidLastName} required />
                                                        </div>
                                                    </>
                                                }
                                                { isGuestWithTwoKidsList === true &&
                                                    <>
                                                        <div className="col-12">
                                                            <label>You can bring your <strong>(2)</strong> children with you.</label><br/>
                                                            <label><strong>First Kid's Name</strong></label>
                                                        </div>
                                                        <div className="col-xs-12 col-md-6 pb-4">
                                                            <label for="kidFirstName">First Name (required)</label>
                                                            <input type="text" className="form-control" name="kidFirstName" id="kidFirstName" onChange={e => setKidFirstName(e.target.value)} value={kidFirstName} required />
                                                        </div>
                                                        <div className="col-xs-12 col-md-6 pb-4">
                                                            <label for="kidLastName">Last Name (required)</label>
                                                            <input type="text" className="form-control" name="kidLastName" id="kidLastName" onChange={e => setKidLastName(e.target.value)} value={kidLastName} required />
                                                        </div>

                                                        <div className="col-12">
                                                            <label><strong>Second Kid's Name</strong></label>
                                                        </div>
                                                        <div className="col-xs-12 col-md-6 pb-4">
                                                            <label for="kidTwoFirstName">First Name (required)</label>
                                                            <input type="text" className="form-control" name="kidTwoFirstName" id="kidTwoFirstName" onChange={e => setKidTwoFirstName(e.target.value)} value={kidTwoFirstName} required />
                                                        </div>
                                                        <div className="col-xs-12 col-md-6 pb-4">
                                                            <label for="kidTwoLastName">Last Name (required)</label>
                                                            <input type="text" className="form-control" name="kidTwoLastName" id="kidTwoLastName" onChange={e => setKidTwoLastName(e.target.value)} value={kidTwoLastName} required />
                                                        </div>
                                                    </>
                                                }
                                                { isGuestWithPlusOneList === true &&
                                                    <>
                                                        <div className="col-12">
                                                            <label>You can bring <strong>(1)</strong> guest with you.</label><br/>
                                                            <label><strong>Guest's Name</strong></label>
                                                        </div>
                                                        <div className="col-xs-12 col-md-6 pb-4">
                                                            <label for="guestFirstName">First Name (required)</label>
                                                            <input type="text" className="form-control" name="guestFirstName" id="guestFirstName" onChange={e => setGuestFirstName(e.target.value)} value={guestFirstName} required />
                                                        </div>
                                                        <div className="col-xs-12 col-md-6 pb-4">
                                                            <label for="guestLastName">Last Name (required)</label>
                                                            <input type="text" className="form-control" name="guestLastName" id="guestLastName" onChange={e => setGuestLastName(e.target.value)} value={guestLastName} required />
                                                        </div>
                                                    </>
                                                }
                                                <div className="col-12 pb-4">
                                                    <label for="allergies">Any food restrictions or allergies? (required)</label>
                                                    <input type="text" className="form-control" name="allergies" id="allergies" onChange={e => setAllergies(e.target.value)} value={allergies} required />
                                                </div>
                                            </>
                                        }
                                        { isGuest === false &&
                                            <>
                                                <div style={{width:'100%'}}>
                                                    <p className="text-small text-center text-garamond py-5">
                                                        Sorry, your name isn't found on our rsvp list. <br/>
                                                        Please use your legal name or nickname plus your last name.
                                                    </p>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className="text-center">
                                        <button type="submit" className="rsvp-button" disabled={isDisabled}>Submit</button>
                                        {loading === true ? (
                                            <div className="py-2">
                                                <div className="spinner"></div>
                                            </div>
                                        ) : (<></>)}
                                    </div>
                                </form>
                            </>
                        ) : (
                            <>
                                <div className="text-center text-garamond text-pink py-5 my-5">
                                    <h2> RSVP is already closed. </h2>
                                    <p> Thank you so much. </p>
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}
export default RSVPForm;