import React from "react";
const Footer = () =>{
    return(
        <div className="section footer text-center no-print">
            <h2 className="text-small text-garamond">Athan &amp; Iris</h2>
            <h3 className="text-medium text-white cursive pt-2 pb-3">Say Yes</h3>
            <h2 className="text-small text-garamond">02.04.2025</h2>
        </div>
    )
}
export default Footer;